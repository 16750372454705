
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import useImageParams from '@/hooks/useImageParams'

export default defineComponent({
  setup() {
    const { t } = useI18n();

    onMounted(() => {
      const { container, imageParams } = useImageParams('.statistics')
      container.style.background = `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('https://res.cloudinary.com/abat/image/upload/${imageParams}/v1635447240/bg-fixed_hhw6a9')`;
    })

    return { t };
  },
});
