import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19a5ef26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { for: "search-car" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f_icon = _resolveComponent("f-icon")!
  const _component_AutoDropdown = _resolveComponent("AutoDropdown")!
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_drop_down = _resolveComponent("drop-down")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['search-bar', { invalid: !_ctx.formIsValid }])
  }, [
    _createVNode(_component_base_card, { class: "form-card" }, {
      default: _withCtx(() => [
        _createElementVNode("label", _hoisted_1, [
          _createVNode(_component_f_icon, { icon: "search" }),
          _createTextVNode(_toDisplayString(_ctx.t("searchAuto")), 1)
        ]),
        _createElementVNode("form", {
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
          autocomplete: "off",
          id: "search-car"
        }, [
          _createVNode(_component_AutoDropdown, {
            id: "autocomplete-from-user",
            label: _ctx.t('from'),
            options: _ctx.townOptions,
            onSelected: _ctx.validateSelection,
            name: "from",
            invalid: _ctx.state.fromInvalid,
            message: _ctx.t('selectOne'),
            maxItem: 10,
            locale: _ctx.locale,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.formIsValid = true))
          }, null, 8, ["label", "options", "onSelected", "invalid", "message", "locale"]),
          _createVNode(_component_AutoDropdown, {
            id: "autocomplete-to-user",
            label: _ctx.t('to'),
            options: _ctx.townOptions,
            onSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateSelection($event, false))),
            name: "to",
            invalid: _ctx.state.toInvalid,
            message: _ctx.t('selectOne'),
            maxItem: 10,
            locale: _ctx.locale,
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.formIsValid = true))
          }, null, 8, ["label", "options", "invalid", "message", "locale"]),
          _createVNode(_component_date_picker, {
            class: "date",
            id: "date",
            label: _ctx.t('when'),
            modelValue: _ctx.state.date,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.date) = $event))
          }, null, 8, ["label", "modelValue"]),
          _createVNode(_component_drop_down, {
            options: _ctx.options,
            modelValue: _ctx.state.type,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.type) = $event)),
            label: _ctx.t('carType'),
            selected: _ctx.state.type
          }, {
            icon: _withCtx(({ option }) => [
              _createElementVNode("img", {
                class: "option-icon",
                src: `/icons/${option.id}.png`,
                alt: option.id
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }, 8, ["options", "modelValue", "label", "selected"]),
          _createVNode(_component_base_button, {
            disabled: !_ctx.formIsValid,
            class: "search-btn",
            mode: "orange"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("search")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ], 32)
      ]),
      _: 1
    })
  ], 2))
}