import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_MainCard = _resolveComponent("MainCard")!
  const _component_Advantages = _resolveComponent("Advantages")!
  const _component_Posts = _resolveComponent("Posts")!
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_Feedback = _resolveComponent("Feedback")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchBar),
    _createVNode(_component_MainCard),
    _createVNode(_component_Advantages),
    _createVNode(_component_Posts),
    _createVNode(_component_Statistics),
    _createVNode(_component_Feedback)
  ]))
}