import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.t("feedback.h1")), 1),
    _createVNode(_component_base_card, { class: "form-card" }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createVNode(_component_base_input, {
            class: "ib",
            id: "fd-name",
            label: _ctx.t('feedback.name'),
            invalid: _ctx.v$.name.$error,
            message: _ctx.v$.name,
            modelValue: _ctx.state.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.name) = $event))
          }, null, 8, ["label", "invalid", "message", "modelValue"]),
          _createVNode(_component_base_input, {
            class: "ib last",
            id: "fd-email",
            label: _ctx.t('feedback.email'),
            invalid: _ctx.v$.email.$error,
            message: _ctx.v$.email,
            modelValue: _ctx.state.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.email) = $event))
          }, null, 8, ["label", "invalid", "message", "modelValue"]),
          _createVNode(_component_base_input, {
            id: "fd-message",
            label: _ctx.t('feedback.message'),
            textarea: "",
            invalid: _ctx.v$.message.$error,
            message: _ctx.v$.message,
            modelValue: _ctx.state.message,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.message) = $event))
          }, null, 8, ["label", "invalid", "message", "modelValue"]),
          _createVNode(_component_base_button, { mode: "orange" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("feedback.submit")), 1)
            ]),
            _: 1
          })
        ], 32),
        (_ctx.isMessageSent)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['success', { invalid: _ctx.messageSentFeedback === 'failed' }])
            }, _toDisplayString(_ctx.t(_ctx.messageSentFeedback)), 3))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}