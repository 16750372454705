
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import type { User } from "firebase/auth";
import useImageParams from "@/hooks/useImageParams";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const user = store.getters.user as User | null;
    const imageUrl = ref("#");

    onMounted(() => {
      const { container: _, imageParams } = useImageParams(".img");
      imageUrl.value = `https://res.cloudinary.com/abat/image/upload/${imageParams}/v1635447239/card_yfcigg`;
    });

    return { t, user, imageUrl };
  },
});
