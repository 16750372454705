
import { defineComponent } from "vue";
import SearchBar from "@/components/Home/SearchBar.vue";
import MainCard from "@/components/Home/MainCard.vue";
import Advantages from "@/components/Home/Advantages.vue";
import Posts from "@/components/Home/Posts.vue";
import Statistics from "@/components/Home/Statistics.vue";
import Feedback from "@/components/Home/Feedback.vue";

export default defineComponent({
  components: { SearchBar, MainCard, Advantages, Posts, Statistics ,Feedback },
  name: "Home",
});
