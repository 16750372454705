
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const img1 = 'https://res.cloudinary.com/abat/image/upload/c_fill,g_auto,f_auto/v1635447240/post1_temnts';
    const img2 = 'https://res.cloudinary.com/abat/image/upload/c_fill,g_auto,f_auto/v1635447240/post2_yqlhke';

    function getPhoto(type: 1 | 2) {
      if (type === 1) return img1;
      return img2
    }

    const posts = [1, 2];

    return { t, posts, getPhoto };
  },
});
