
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, email } from "@vuelidate/validators";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isMessageSent = ref(false);
    const messageSentFeedback = ref("");

    const state = reactive({
      type: "feedback",
      name: "",
      email: "",
      message: "",
    });

    const rules = {
      name: { required: helpers.withMessage(t("required"), required) },
      email: {
        required: helpers.withMessage(t("required"), required),
        email: helpers.withMessage(t("email"), email),
      },
      message: {
        required: helpers.withMessage(t("required"), required),
        minLength: helpers.withMessage(t("minL"), minLength(10)),
      },
    };
    const v$ = useVuelidate(rules, state, { $autoDirty: true });

    const onSubmit = async () => {
      const isValid = await v$.value.$validate();
      if (!isValid) return;
      messageSentFeedback.value = await store.dispatch("sendMessage", state);
      isMessageSent.value = true;
      state.name = "";
      state.email = "";
      state.message = "";
      v$.value.$reset();
    };

    return { t, v$, onSubmit, state, isMessageSent, messageSentFeedback };
  },
});
