export default function useImageParams(selector: string) {
  const pixelRatio = window.devicePixelRatio || 1.0;

  const container = document.querySelector(selector)! as HTMLDivElement;
  const { clientWidth, clientHeight } = container;
  const imageParams = `w_${100 *
    Math.round((clientWidth * pixelRatio) / 100)},h_${100 *
    Math.round(((clientHeight || 300) * pixelRatio) / 100)},c_fill,g_auto,f_auto`;

  return { container, imageParams };
}
