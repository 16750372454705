
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    let url: string;

    const getImage = (i: number) => {
      switch (i) {
        case 0:
          url =
            "https://res.cloudinary.com/abat/image/upload/c_fill,g_auto,f_auto/v1635484602/icons/0_turev9";
          break;
        case 1:
          url =
            "https://res.cloudinary.com/abat/image/upload/c_fill,g_auto,f_auto/v1635484602/icons/1_k6r0mq";
          break;
        case 2:
          url =
            "https://res.cloudinary.com/abat/image/upload/c_fill,g_auto,f_auto/v1635484602/icons/2_oo1rjm";
          break;
        case 3:
          url =
            "https://res.cloudinary.com/abat/image/upload/c_fill,g_auto,f_auto/v1635484602/icons/3_zwccvv";
          break;
        case 4:
          url =
            "https://res.cloudinary.com/abat/image/upload/c_fill,g_auto,f_auto/v1635484602/icons/4_hz23q0";
          break;
        case 5:
          url =
            "https://res.cloudinary.com/abat/image/upload/c_fill,g_auto,f_auto/v1635484602/icons/5_ml8r3p";
          break;

        default:
          url = "no";
          break;
      }
      return url;
    };

    const advantages = [1, 2, 3, 4, 5, 6];

    return { t, advantages, getImage };
  },
});
