
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import DatePicker from "../Datepicker.vue";
import DropDown from "@/components/Dropdown.vue";
import AutoDropdown from "../AutoDropdown.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useImageParams from "@/hooks/useImageParams";

export default defineComponent({
  components: { DatePicker, DropDown, AutoDropdown },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();
    const formIsValid = ref(true);
    const allOptions: Ref<any> = ref({});
    const townOptions: Ref<any[]> = ref([]);
    const state = reactive<{ [key: string]: any }>({
      from: null,
      fromInvalid: false,
      to: null,
      toInvalid: false,
      date: new Date(),
      type: "car",
    });

    const townsURL = store.getters.towns;

    onMounted(() => {
      const { container, imageParams } = useImageParams(".search-bar");
      container.style.background = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/abat/image/upload/${imageParams}/v1635447243/search-bg_sjtd8p')`;

      fetchResults(true);
    });

    watch(locale, (val) => fetchResults(false, val));

    // Говно код старт
    async function fetchResults(reFetch = false, lang = '') {
      if (reFetch) {
        const res = await fetch(townsURL);
        allOptions.value = await res.json();
      }
      const val = lang === 'kz' ? 'qq-cyr' : locale.value;
      townOptions.value = [];
      for (const key in allOptions.value) {
        const str = { id: key, name: allOptions.value[key][val] };
        townOptions.value.push(str);
      }
    }

    const validateSelection = (
      data: { id: string; name: string },
      fromFrom = true
    ) => {
      if (data) {
        if (fromFrom) {
          state.from = data.id;
          state.fromInvalid = false;
        } else {
          state.to = data.id;
          state.toInvalid = false;
        }
      }
    };
    // Говно код финиш

    const options = computed(() => [
      { id: "car", name: t("carOptions.car") },
      { id: "bus", name: t("carOptions.bus") },
      { id: "truck", name: t("carOptions.truck") },
      { id: "delivery", name: t("carOptions.delivery") },
    ]);

    const submitForm = () => {
      if (!state.from) state.fromInvalid = true;
      if (!state.to) state.toInvalid = true;
      const isNotValid = state.fromInvalid || state.toInvalid;
      if (isNotValid) {
        formIsValid.value = false;
        return;
      }
      
      formIsValid.value = true;

      router.push({
        path: "results",
        query: {
          from: state.from,
          to: state.to,
          type: state.type,
          date: state.date.toISOString(),
        },
      });
    };

    return {
      t,
      locale,
      state,
      options,
      submitForm,
      formIsValid,
      validateSelection,
      townOptions,
    };
  },
});
