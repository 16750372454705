import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b9804e12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cards" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.t("advantages.h1")), 1),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.advantages, (item, i) => {
        return (_openBlock(), _createBlock(_component_base_card, { key: item }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.getImage(i),
              alt: item
            }, null, 8, _hoisted_2),
            _createElementVNode("span", null, _toDisplayString(_ctx.t(`advantages.${item}.span`)), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.t(`advantages.${item}.p`)), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ], 64))
}