import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8db8eece"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "posts" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.t("posts.h1")), 1),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "post",
          key: post
        }, [
          _createElementVNode("img", {
            src: _ctx.getPhoto(post),
            alt: "post"
          }, null, 8, _hoisted_2),
          _createElementVNode("h2", null, _toDisplayString(_ctx.t(`posts.${post}.title`)), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.t(`posts.${post}.desc`)), 1),
          _createVNode(_component_base_button, {
            mode: "orange",
            class: "btn",
            to: _ctx.t(`posts.${post}.link`),
            link: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("posts.readMore")), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ], 64))
}